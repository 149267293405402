import * as colors from './colors'
import { lighten, darken } from 'polished'

// Themes (ThemeSelector Component)
export const themes = {
	default: {
		color: colors.textColor,
		background: colors.bgColor,
		hoverColor: colors.mainColor,
		buttonTheme: 'default',
		buttonThemeSecondary: 'transparent'
	},
	black: {
		color: colors.bgColor,
		background: colors.black,
		hoverColor: colors.mainColor,
		buttonTheme: 'white',
		buttonThemeSecondary: 'currentcolorOutlined'
	},
	white: {
		color: colors.textColor,
		background: colors.white,
		hoverColor: colors.mainColor,
		buttonTheme: 'default',
		buttonThemeSecondary: 'lightGrey'
	},
	lightGrey: {
		color: colors.textColor,
		background: colors.lightGrey,
		hoverColor: colors.textColor,
		buttonTheme: 'default',
		buttonThemeSecondary: 'white'
	},
	mainColor: {
		color: colors.bgColor,
		background: colors.mainColor,
		hoverColor: colors.bgColor,
		buttonTheme: 'white',
		buttonThemeSecondary: 'lightGrey'
	},
	textColor: {
		color: colors.bgColor,
		background: colors.textColor,
		hoverColor: colors.bgColor,
		buttonTheme: 'default',
		buttonThemeSecondary: 'lightGrey'
	},
	transparent: {
		color: colors.textColor,
		background: colors.transparent,
		hoverColor: colors.bgColor,
		buttonTheme: 'default',
		buttonThemeSecondary: 'lightGrey'
	},
	pink: {
		color: colors.textColor,
		background: colors.pink,
		hoverColor: colors.mainColor,
		buttonTheme: 'default',
		buttonThemeSecondary: 'transparent'
	},
	blue: {
		color: colors.textColor,
		background: colors.blue,
		hoverColor: colors.mainColor,
		buttonTheme: 'default',
		buttonThemeSecondary: 'transparent'
	},
	green: {
		color: colors.textColor,
		background: colors.green,
		hoverColor: colors.mainColor,
		buttonTheme: 'default',
		buttonThemeSecondary: 'transparent'
	},
	yellow: {
		color: colors.textColor,
		background: colors.yellow,
		hoverColor: colors.mainColor,
		buttonTheme: 'default',
		buttonThemeSecondary: 'transparent'
	}

}

// Button Themes
export const buttonThemes = {
	default: {
		color: '#DD5326',
		background: colors.transparent,
		borderColor: '#DD5326',
		hoverColor: colors.bgColor,
		hoverBackground: '#DD5326',
		borderHoverColor: '#DD5326'
	},
	transparent: {
		color: '#224925',
		background: colors.transparent,
		borderColor: '#224925',
		hoverColor: colors.bgColor,
		hoverBackground: '#224925',
		borderHoverColor: '#224925'
	},
	currentcolor: {
		color: colors.textColor,
		background: 'currentcolor',
		hoverColor: 'currentcolor',
		hoverBackground: 'currentcolor'
	},
	currentcolorOutlined: {
		color: 'currentcolor',
		background: 'transparent',
		hoverColor: colors.textColor,
		hoverBackground: 'currentcolor',
		borderColor: 'currentcolor'
	},
	black: {
		color: colors.bgColor,
		background: colors.black,
		hoverColor: colors.mainColor,
		hoverBackground: darken(0.07, colors.mainColor)
	},
	white: {
		color: colors.textColor,
		background: colors.white,
		hoverColor: colors.mainColor,
		hoverBackground: colors.lightGrey
	},
	lightGrey: {
		color: colors.textColor,
		background: colors.lightGrey,
		hoverColor: colors.textColor,
		hoverBackground: darken(0.07, colors.lightGrey)
	},
	mainColor: {
		color: colors.bgColor,
		background: '#DD5326',
		hoverColor: '#DD5326',
		borderColor: '#DD5326',
		hoverBackground: colors.transparent,
		borderHoverColor: '#DD5326'
	},
	textColor: {
		color: colors.bgColor,
		background: colors.textColor,
		hoverColor: colors.bgColor,
		hoverBackground: lighten(0.2, colors.textColor)
	}
}

// Input Themes
export const inputThemes = {
	default: {
		color: colors.textColor,
		placeholderColor: colors.lightTextColor,
		background: colors.transparent,
		accentColor: colors.bgColor,
		hoverColor: colors.bgColor,
		borderColor: colors.black,
		hoverBorderColor: colors.black,
		focusBorderColor: colors.black,
		hoverBackground: colors.transparent,
		focusBackground: colors.transparent,
	}
}

export default themes
