import MaterialIconsWoff from '../assets/fonts/icons/material-icons-regular.woff'
import MaterialIconsWoff2 from '../assets/fonts/icons/material-icons-regular.woff2'

import AktivWoff from '../assets/fonts/aktiv/Aktiv-Regular.woff'
import AktivWoff2 from '../assets/fonts/aktiv/Aktiv-Regular.woff2'

import AktivBoldWoff from '../assets/fonts/aktiv/Aktiv-Bold.woff'
import AktivBoldWoff2 from '../assets/fonts/aktiv/Aktiv-Bold.woff2'

import AktivMediumWoff from '../assets/fonts/aktiv/Aktiv-Medium.woff'
import AktivMediumWoff2 from '../assets/fonts/aktiv/Aktiv-Medium.woff2'

import AktivLightWoff from '../assets/fonts/aktiv/Aktiv-Light.woff'
import AktivLightWoff2 from '../assets/fonts/aktiv/Aktiv-Light.woff2'

import RecklessRegularWoff from '../assets/fonts/reckless/Reckless-Regular.woff'
import RecklessRegularWoff2 from '../assets/fonts/reckless/Reckless-Regular.woff2'

import RecklessRegularItalicWoff from '../assets/fonts/reckless/Reckless-RegularItalic.woff'
import RecklessRegularItalicWoff2 from '../assets/fonts/reckless/Reckless-RegularItalic.woff2'

import RecklessMediumWoff from '../assets/fonts/reckless/Reckless-Medium.woff'
import RecklessMediumWoff2 from '../assets/fonts/reckless/Reckless-Medium.woff2'

import RecklessMediumItalicWoff from '../assets/fonts/reckless/Reckless-MediumItalic.woff'
import RecklessMediumItalicWoff2 from '../assets/fonts/reckless/Reckless-MediumItalic.woff2'

import RecklessLightWoff from '../assets/fonts/reckless/Reckless-Light.woff'
import RecklessLightWoff2 from '../assets/fonts/reckless/Reckless-Light.woff2'

import RecklessLightItalicWoff from '../assets/fonts/reckless/Reckless-LightItalic.woff'
import RecklessLightItalicWoff2 from '../assets/fonts/reckless/Reckless-LightItalic.woff2'

import AxalpRegularWoff from '../assets/fonts/axalp/AxalpGroteskRegular.woff'
import AxalpRegularWoff2 from '../assets/fonts/axalp/AxalpGroteskRegular.woff2'

import AxalpRegularItalicWoff from '../assets/fonts/axalp/AxalpGroteskRegularItalic.woff'
import AxalpRegularItalicWoff2 from '../assets/fonts/axalp/AxalpGroteskRegularItalic.woff2'

import AxalpExtraBoldWoff from '../assets/fonts/axalp/AxalpGroteskExtraBold.woff'
import AxalpExtraBoldWoff2 from '../assets/fonts/axalp/AxalpGroteskExtraBold.woff2'

import AxalpExtraBoldItalicWoff from '../assets/fonts/axalp/AxalpGroteskExtraBoldItalic.woff'
import AxalpExtraBoldItalicWoff2 from '../assets/fonts/axalp/AxalpGroteskExtraBoldItalic.woff2'

import AxalpDemiBoldWoff from '../assets/fonts/axalp/AxalpGroteskDemiBold.woff'
import AxalpDemiBoldWoff2 from '../assets/fonts/axalp/AxalpGroteskDemiBold.woff2'

import AxalpDemiBoldItalicWoff from '../assets/fonts/axalp/AxalpGroteskDemiBoldItalic.woff'
import AxalpDemiBoldItalicWoff2 from '../assets/fonts/axalp/AxalpGroteskDemiBoldItalic.woff2'

export const fontFace = (fontName, woff, woff2, fontWeight = 'normal', fontStyle = 'normal') => `
	@font-face {
		font-family: '${ fontName }';
		src:  url('${ woff }') format('woff'),
					url('${ woff2 }') format('woff2');
		font-weight: ${ fontWeight };
		font-style: ${ fontStyle };
		font-display: swap;
	}
`
export const MaterialIcons = 'Material Icons'
export const MaterialIconsFont = fontFace(MaterialIcons, MaterialIconsWoff, MaterialIconsWoff2)

export const Aktiv = 'Aktiv'
export const AktivFont = fontFace(Aktiv, AktivWoff, AktivWoff2)
export const AktivBoldFont = fontFace(Aktiv, AktivBoldWoff, AktivBoldWoff2, '600')
export const AktivLightFont = fontFace(Aktiv, AktivLightWoff, AktivLightWoff2, '300')
export const AktivMediumFont = fontFace(Aktiv, AktivMediumWoff, AktivMediumWoff2, '500')

export const Reckless = 'Reckless'
export const RecklessFont = fontFace(Reckless, RecklessRegularWoff, RecklessRegularWoff2)
export const RecklessItalicFont = fontFace(Reckless, RecklessRegularItalicWoff, RecklessRegularItalicWoff2, 'normal', 'italic')

export const RecklessBoldFont = fontFace(Reckless, RecklessMediumWoff, RecklessMediumWoff2, '600')
export const RecklessBoldItalicFont = fontFace(Reckless, RecklessMediumItalicWoff, RecklessMediumItalicWoff2, '600', 'italic')

export const RecklessLightFont = fontFace('Reckless-Light', RecklessLightWoff, RecklessLightWoff2, '300')
export const RecklessLightItalicFont = fontFace(Reckless, RecklessLightItalicWoff, RecklessLightItalicWoff2, '300', 'italic')



export const Axalp = 'Axalp Grotesk'
export const AxalpFont = fontFace(Axalp, AxalpDemiBoldWoff, AxalpDemiBoldWoff2, '500')
export const AxalpItalicFont = fontFace(Axalp, AxalpDemiBoldItalicWoff, AxalpDemiBoldItalicWoff2, 'normal', 'italic')

export const AxalpBoldFont = fontFace(Axalp, AxalpExtraBoldWoff, AxalpExtraBoldWoff2, '600')
export const AxalpBoldItalicFont = fontFace(Axalp, AxalpExtraBoldItalicWoff, AxalpExtraBoldItalicWoff2, '600', 'italic')

export const AxalpLightFont = fontFace(Axalp, AxalpRegularWoff, AxalpRegularWoff2, '400')
export const AxalpLightItalicFont = fontFace(Axalp, AxalpRegularItalicWoff, AxalpRegularItalicWoff2, '300', 'italic')