import { lighten, darken, rgba } from 'polished'

export const black = '#322E22'
export const white = '#fff'

// Site Specific Colors
export const yellow = '#F4D379'
export const pink = '#F1C8DA'
export const blue = '#B5D4E8'
export const green = '#A7CEC1'
export const grey = '#E2E0D7'
export const orange = '#DD5326'
export const highlight = '#EBE9E1'
export const darkGreen = '#224925'

// Basic Colors
export const transparent = 'transparent'
export const currentcolor = 'currentcolor'
export const bgColor = grey
// export const bgColor = '#224925'
export const mainColor = black
export const alert = pink
export const notify = yellow
export const success = green
export const textColor = black
export const lightTextColor = rgba(textColor, 0.4)
export const lightGrey = '#F4F3EB'
export const hrColor = rgba(textColor, 0.1)

// Color Variations
export const mainColorDarken = darken(0.07, mainColor)
// export const darkGrey = darken(0.07, lightGrey)
export const darkGrey = lighten(0.5, black)
export const lighterGrey = lighten(0.5, lightGrey)
export const mainColorLighten = lighten(0.07, mainColor)
export const yellowDarken = '#FEBE10'
