import { rgba } from 'polished'
import * as colors from './colors'
import { responsiveStyles } from './util'

import './fonts'

// Place global Typography in this file
export const primaryFont = 'Reckless, -apple-system, serif'
export const secondaryFont = 'Axalp Grotesk, -apple-system, serif'
export const medium = 500
export const bold = 600
export const normal = 400

export const bodyXlarge = `
	${ responsiveStyles('font-size', 36, 36, 36, 36) }
	line-height: 1.75em;
	font-family: ${ primaryFont };
	letter-spacing: 0.02em;
	text-transform: none;
	font-weight: normal;
`

export const bodyLarge = `
	${ responsiveStyles('font-size', 20, 26, 36, 36) }
	line-height: 1.5em;
	font-family: ${ primaryFont };
	letter-spacing: 0.02em;
	text-transform: none;
	font-weight: normal;
`
export const bodyMedium = `
	${ responsiveStyles('font-size', 18, 18, 18, 18) }
	line-height: 1.55em;
	font-family: ${ primaryFont };
	letter-spacing: 0.02em;
	text-transform: none;
	font-weight: normal;
`
export const body = `
	${ responsiveStyles('font-size', 18, 18, 18, 18) }
	line-height: 1.55em;
	font-family: ${ primaryFont };
	letter-spacing: 0.02em;
	text-transform: none;
	font-weight: normal;
`
export const bodySmall = `
	${ responsiveStyles('font-size', 12, 12, 12, 12) }
	line-height: 1.6em;
	font-family: ${ primaryFont };
	letter-spacing: 0.02em;
	text-transform: none;
	font-weight: normal;
`

export const h1 = `
	${ responsiveStyles('font-size', 96, 96, 96, 96) }
	// ${ responsiveStyles('font-size', 80, 70, 50, 30) }
	line-height: 1em;
	font-family: 'Reckless-Light';
	font-weight: 300;
	letter-spacing: 0;
	text-transform: none;
`

export const h2 = `
	// ${ responsiveStyles('font-size', 60, 44, 36, 22) }
	${ responsiveStyles('font-size', 48, 48, 48, 36) }
	line-height: 1em;
	font-family: 'Reckless-Light';
	font-weight: 300;
	// font-weight: normal;
	letter-spacing: 0;
	text-transform: none;
`

export const h3 = `
	// ${ responsiveStyles('font-size', 52, 36, 30, 22) }
	${ responsiveStyles('font-size', 22, 22, 22, 22) }
	line-height: 1em;
	font-family: ${ secondaryFont };
	font-weight: 500;
	letter-spacing: 0;
	text-transform: none;
`

export const h4 = `
	// ${ responsiveStyles('font-size', 36, 30, 26, 18) }
	${ responsiveStyles('font-size', 16, 16, 16, 16) }
	line-height: 1.25em;
	font-family: ${ secondaryFont };
	font-weight: 500;
	letter-spacing: 0;
	text-transform: none;
`

export const h5 = `
	${ responsiveStyles('font-size', 32, 30, 26, 26) }
	line-height: 1.5em;
	font-family: ${ primaryFont };
	letter-spacing: 0.02em;
	text-transform: none;
	font-weight: normal;
`
export const h6 = `
	${ responsiveStyles('font-size', 16, 14, 14, 12) }
	font-family: ${ secondaryFont };
	font-weight: 500;
	line-height: 1.25em;
	letter-spacing: .1em;
	text-transform: uppercase;
`

export const blockquote = `
	${ bodyLarge }
	font-style: normal;
`

export const tabHeader = `
// ${ responsiveStyles('font-size', 30, 24, 20, 16) }
	${ responsiveStyles('font-size', 12, 12, 12, 12) }
	font-family: ${ secondaryFont };
	font-weight: 500;
	line-height: 1.25em;
	letter-spacing: 0;
`

export const quoteLg = `
	${ responsiveStyles('font-size', 52, 36, 30, 22) }
	font-family: ${ primaryFont };
	font-style: italic;
	line-height: 1.2em;
`

export const pageTitle = `
	font-family: "Reckless-Light";
	// ${ responsiveStyles('font-size', 22, 30, 36, 52) }
	${ responsiveStyles('font-size', 84, 60, 60, 48) }
	line-height: 0.75em;
	
`

export const compactLink = `
	font-family: ${ primaryFont };
	${ responsiveStyles('font-size', 16, 16, 16, 16) }
	line-height: 1.3;
`

export const eyebrow = `
	${ h6 }
`

export const buttonStyle = `
	${ h4 }
	font-weight: 400;
	text-transform: uppercase;
	font-family: ${ secondaryFont };
	${ responsiveStyles('font-size', 14, 14, 14, 13) }
	line-height: 1em;
`

export const storyNotes = `
	max-width: 750px;
	p {
		code {
			background: ${ rgba(colors.textColor, 0.1) };
			color: ${ colors.textColor };
			border-radius: 3px;
			padding: .05em .35em .15em;
			font-style: normal;
		}
	}
`
